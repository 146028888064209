<template>
    <div class="auth-wrapper auth-v2">
        <b-row class="auth-inner m-0">
            <!-- Brand logo-->
            <b-link class="brand-logo">
                <h2 class="brand-text text-primary ml-1">OMRON</h2>
            </b-link>
            <!-- /Brand logo-->

            <!-- Left Text-->
            <b-col lg="8" class="d-none d-lg-flex align-items-center p-5">
                <div
                    class="w-100 d-lg-flex align-items-center justify-content-center rounded px-5"
                >
                    <b-carousel
                        id="carousel-interval"
                        controls
                        indicators
                        :interval="5000"
                        class="rounded"
                    >
                        <b-carousel-slide img-src="img/slider/slider_1.jpg" />
                        <b-carousel-slide img-src="img/slider/slider_2.jpg" />
                    </b-carousel>
                </div>
            </b-col>
            <!-- /Left Text-->

            <!-- Login-->
            <b-col lg="4" class="d-flex align-items-center auth-bg px-2 p-lg-5">
                <b-col sm="8" md="6" lg="12" class="px-xl-2 mx-auto">
                    <b-card-title title-tag="h2" class="font-weight-bold mb-1">
                        Bienvenido a OMRON
                    </b-card-title>
                    <b-card-text class="mb-2">
                        Por favor inicia sesión
                    </b-card-text>

                    <!-- form -->
                    <validation-observer ref="loginValidation">
                        <b-form class="auth-login-form mt-2" @submit.prevent>
                            <!-- email -->
                            <b-form-group label="Email" label-for="login-email">
                                <validation-provider
                                    #default="{ errors }"
                                    name="email"
                                    rules="required|email"
                                >
                                    <b-form-input
                                        id="login-email"
                                        v-model="userEmail"
                                        :state="
                                            errors.length > 0 ? false : null
                                        "
                                        name="login-email"
                                        placeholder="ejemplo@ejemplo.com"
                                    />
                                    <small class="text-danger">{{
                                        errors[0]
                                    }}</small>
                                </validation-provider>
                            </b-form-group>

                            <!-- forgot password -->
                            <b-form-group>
                                <div class="d-flex justify-content-between">
                                    <label for="login-password"
                                        >Contraseña</label
                                    >
                                </div>
                                <validation-provider
                                    #default="{ errors }"
                                    name="contraseña"
                                    rules="required"
                                >
                                    <b-input-group
                                        class="input-group-merge"
                                        :class="
                                            errors.length > 0
                                                ? 'is-invalid'
                                                : null
                                        "
                                    >
                                        <b-form-input
                                            id="login-password"
                                            v-model="password"
                                            :state="
                                                errors.length > 0 ? false : null
                                            "
                                            class="form-control-merge"
                                            :type="passwordFieldType"
                                            name="login-password"
                                            placeholder="············"
                                        />
                                        <b-input-group-append is-text>
                                            <feather-icon
                                                class="cursor-pointer"
                                                :icon="passwordToggleIcon"
                                                @click="
                                                    togglePasswordVisibility
                                                "
                                            />
                                        </b-input-group-append>
                                    </b-input-group>
                                    <small class="text-danger">{{
                                        errors[0]
                                    }}</small>
                                </validation-provider>
                            </b-form-group>
                            <div class="text-right mb-2">
                                <b-link
                                    :to="{ name: 'auth-forgot-password-v2' }"
                                >
                                    <small>¿Olvidaste la contraseña?</small>
                                </b-link>
                            </div>

                            <!-- checkbox -->
                            <!-- <b-form-group>
                <b-form-checkbox
                  id="remember-me"
                  v-model="status"
                  name="checkbox-1"
                >
                  Remember Me
                </b-form-checkbox>
              </b-form-group> -->

                            <!-- submit buttons -->
                            <b-button
                                type="submit"
                                variant="primary"
                                block
                                @click="validationForm"
                            >
                                Entrar
                            </b-button>
                        </b-form>
                    </validation-observer>

                    <b-card-text class="text-center my-2">
                        <b-link :to="{ name: 'page-auth-register-v2' }">
                            <span>Crea una cuenta</span>
                        </b-link>
                    </b-card-text>

                    <!-- divider -->
                    <div class="divider my-2">
                        <div class="divider-text">or</div>
                    </div>

                    <!-- social buttons -->
                    <div class="auth-footer-btn d-flex justify-content-center">
                        <b-button variant="facebook" href="javascript:void(0)">
                            <feather-icon icon="FacebookIcon" />
                        </b-button>
                        <b-button variant="twitter" href="javascript:void(0)">
                            <feather-icon icon="TwitterIcon" />
                        </b-button>
                        <b-button variant="google" href="javascript:void(0)">
                            <feather-icon icon="MailIcon" />
                        </b-button>
                        <b-button variant="github" href="javascript:void(0)">
                            <feather-icon icon="GithubIcon" />
                        </b-button>
                    </div>
                </b-col>
            </b-col>
            <!-- /Login-->
        </b-row>
    </div>
</template>

<script>
/* eslint-disable global-require */
import { ValidationProvider, ValidationObserver } from "vee-validate";
import VuexyLogo from "@core/layouts/components/Logo.vue";
import { required, email } from "@validations";
import { togglePasswordVisibility } from "@core/mixins/ui/forms";
import store from "@/store/index";
import Auth from "@/services/auth";
export default {
    components: {
        VuexyLogo,
        ValidationProvider,
        ValidationObserver,
    },
    mounted() {
        store.commit("auth/revokeToken");
    },
    mixins: [togglePasswordVisibility],
    data() {
        return {
            status: "",
            password: "",
            userEmail: "",
            sideImg: require("@/assets/images/pages/login-v2.svg"),
            // validation rulesimport store from '@/store/index'
            required,
            email,
        };
    },
    computed: {
        passwordToggleIcon() {
            return this.passwordFieldType === "password"
                ? "EyeIcon"
                : "EyeOffIcon";
        },
    },
    methods: {
        async validationForm() {
            let validate = await this.$refs.loginValidation.validate();

            if (!validate)
                return this.notificacion(
                    "Por favor completa los campos",
                    "AlertTriangleIcon",
                    "warning"
                );

            try {
                let params = {
                    email: this.userEmail,
                    password: this.password,
                };
                const { data } = await Auth.login(params);

                await store.dispatch("auth/assignToken", data);

                this.$router.replace({ name: "loading-page" });

                this.notificacion(
                    `Bienvenido ${data.user.nombre}`,
                    "CheckIcon",
                    "success"
                );
            } catch (error) {
                this.notificacion(error.message, "XIcon", "danger");
            }
        },
    },
};
</script>

<style lang="scss">
@import "@core/scss/vue/pages/page-auth.scss";
</style>
